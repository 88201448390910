import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Page } from '../../components/page';
import { Header } from '../../components/header';
import { useDispatch, useSelector } from '../../core/hooks/redux';
import { ProductOrderCard } from './partials/card';
import {
  addQuantity,
  deleteCartItem,
  emptyCart,
  setComment,
  subQuantity,
} from '../../core/redux/cart';
import { useGetAllowed } from '../../core/helpers/limits';
import {
  useGetMachineOptionsQuery,
  useGetProjectOptionsQuery,
  useGetUserQuery,
  usePostOrderMutation,
} from '../../core/redux/webshop';
import { NewOrder } from '../../core/types/api/newOrder';
import { OrderType } from '../../core/types/enums/orderType';
import { usePagePath } from '../../core/hooks/usePageType';
import { SearchSelectDirect } from '../../components/searchSelect';
import { SelectItem } from '../../core/types/util/selectItem';
import './style.scss';
import { RegionRadio } from '../../components/regionRadio';
import { InputField } from '../../components/inputField';
import { DeliveryMethod } from '../../core/types/enums/delieryMethod';
import { useHasRoles } from '../../msal';
import { UrgentModal } from './partials/urgentModal';

const stableProjects: SelectItem[] = [];
const stableMachines: SelectItem[] = [];

export const CheckoutPage = ({ type }: { type: OrderType }) => {
  const { hasRole: isExternal } = useHasRoles('external');
  const { data: projects = stableProjects } = useGetProjectOptionsQuery(
    undefined,
    { skip: type === 'Clothing' },
  );
  const { data: machines = stableMachines } = useGetMachineOptionsQuery(
    undefined,
    { skip: type === 'Clothing' || isExternal },
  );

  const [post] = usePostOrderMutation();

  const { data: user, isError: errorLoadingUser } = useGetUserQuery();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopPath = usePagePath();

  const [selectMachine, setSelectMachine] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<number | undefined>();
  const [selectedMachine, setSelectedMachine] = useState<string | undefined>();
  const [urgent, setUrgent] = useState<boolean>(false);
  const [urgentModal, setUrgentModal] = useState<boolean>(false);
  const [region, setRegion] = useState<number>(1);
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(
    type === 'Clothing' ? 'NotApplicable' : 'Pickup',
  );
  const [deliveryComment, setOrderComment] = useState<string>('');
  const [externalProject, setExternalProject] = useState<string>('');

  useEffect(() => {
    if (!isExternal || projects.length < 1) return;
    setSelectedProject(projects[0].id as number);
  }, [projects, isExternal]);

  useEffect(() => {
    if (!user) return;
    setRegion(user.region);
  }, [user]);

  const setValue = (v: number | string | undefined) => {
    if (selectMachine) {
      if (typeof v === 'string' || v === undefined) setSelectedMachine(v);
    } else if (typeof v === 'number' || v === undefined) setSelectedProject(v);
  };

  const items = useSelector((s) => s.cart.items.filter((i) => i.type === type));

  const getAllowed = useGetAllowed();

  const deliveryCommentValid = useMemo(
    () => deliveryMethod !== 'DeliveryRequested' || deliveryComment.trim() !== '',
    [deliveryMethod, deliveryComment],
  );
  const externalProjectValid = useMemo(
    () => !isExternal || externalProject.trim() !== '',
    [isExternal, externalProject],
  );

  const isValid = useMemo(() => {
    if (type === 'Equipment') {
      if (selectMachine && !selectedMachine) return false;
      if (!selectMachine && !selectedProject) return false;
      if (isExternal && !externalProjectValid) return false;
      return deliveryCommentValid;
    }
    return items.every(
      (i) => (i.product && getAllowed(i.product) + 1 > 0)
        || (i.comment && i.comment.trim().length !== 0),
    );
  }, [type, items, getAllowed, deliveryCommentValid]);

  const isExtra = useMemo(
    () => (type === 'Clothing'
      ? items.some((i) => i.product && getAllowed(i.product) + 1 < 1)
      : true),
    [type, items, getAllowed],
  );

  const [isSendingOrder, setSendingOrder] = useState<boolean>(false);
  const sendOrder = () => {
    setSendingOrder(true);
    const body: NewOrder = {
      type,
      urgent,
      regionId: region,
      extra: isExtra,
      deliveryMethod,
      deliveryComment,
      externalProject: isExternal ? externalProject : undefined,
      rows: items.map((i) => ({
        id: i.id,
        amount: i.amount * i.product.amountPerUnit,
        comment: i.comment,
      })),
    };
    if (type === 'Equipment') {
      if (selectMachine) body.machineId = selectedMachine;
      else body.projectId = selectedProject;
    }
    post(body)
      .unwrap()
      .then(() => {
        dispatch(emptyCart(type));
        toast.success('Bestilling opprettet!');
        navigate('/');
      })
      .catch(() => {
        toast.error('Kunne ikke opprette bestilling');
      })
      .finally(() => {
        setSendingOrder(false);
      });
  };

  const message = useMemo(() => {
    if (type === 'Equipment' && region === 1) {
      return 'Utstyrsbestillinger må være inne innen klokken 12, for å få varer klar til 15.00';
    }
    if (type === 'Equipment' && region === 2) {
      return 'Utstyrsbestillinger må være inne innen klokken 12, for at varer er klar på Tau til neste dag 15.00';
    }
    if (urgent && region === 1) {
      return 'Hastebestillinger klær må være inne før klokken 11, for at de er klar i hente-bu samme dag.';
    }
    if (urgent && region === 2) {
      return 'Hastebestillinger klær må være inne før klokken 11, for at de er klar i hente-bu på Tau neste dag.';
    }
    return 'Ordinær klesbestilling må være inne før 24. i inneværende måned for å få de utlevert 1. onsdag i påfølgende måned.';
  }, [urgent, region, type]);

  const handleUrgent = () => {
    if (urgent) setUrgent(false);
    else setUrgentModal(true);
  };

  return (
    <Page className="checkout-page">
      <Header showBack>Kasse</Header>
      <Box
        sx={{
          flex: 1,
          padding: 2,
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
          gap={2}
          sx={{
            flex: 1,
            maxWidth: 1200,
            marginX: 'auto',
          }}
        >
          <Box className="checkout-items-container">
            {items.length >= 1 ? (
              items.map((i) => (
                <ProductOrderCard
                  key={i.id}
                  item={i}
                  disableEdit={isSendingOrder}
                  getAllowed={getAllowed}
                  subQuantity={() => dispatch(subQuantity(i.id))}
                  addQuantity={() => dispatch(addQuantity(i.id))}
                  deleteCartItem={() => dispatch(deleteCartItem(i.id))}
                  showComment={type !== 'Equipment'}
                  setComment={(id, comment) => dispatch(setComment({ id, comment }))}
                />
              ))
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Typography fontSize="24px" mb={2}>
                  Handlekurven er tom
                </Typography>
                {shopPath && (
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => navigate(shopPath)}
                  >
                    Fortsett å handle
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {type === 'Equipment' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <FormLabel id="delivery-method-label">Belastes</FormLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                    flexGrow={1}
                    flexDirection="row"
                    gap={2}
                    width="100%"
                  >
                    <ButtonGroup>
                      <Button
                        disabled={isSendingOrder || isExternal}
                        onClick={() => setSelectMachine(false)}
                        variant={!selectMachine ? 'contained' : undefined}
                      >
                        Prosjekt
                      </Button>
                      <Button
                        disabled={isSendingOrder || isExternal}
                        onClick={() => setSelectMachine(true)}
                        variant={selectMachine ? 'contained' : undefined}
                      >
                        Maskin
                      </Button>
                    </ButtonGroup>
                    <Box sx={{ minWidth: 200, flex: 1 }}>
                      <SearchSelectDirect
                        required
                        errorLabel=""
                        inputSx={{ flex: 1 }}
                        onChange={setValue}
                        disabled={isSendingOrder || isExternal}
                        value={
                          selectMachine ? selectedMachine : selectedProject
                        }
                        label={selectMachine ? 'Maskin' : 'Prosjekt'}
                        size="small"
                      >
                        {selectMachine ? machines : projects}
                      </SearchSelectDirect>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {isExternal && (
                <InputField
                  fullWidth
                  error={!externalProjectValid}
                  sx={{ mt: 1 }}
                  disabled={isSendingOrder}
                  label="Ekstern prosjekt"
                  value={externalProject}
                  onChange={(e) => setExternalProject(e.slice(0, 200))}
                  size="small"
                />
              )}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                }}
              >
                <Box display="flex" flexDirection="column">
                  <FormLabel id="delivery-method-label">Levering</FormLabel>
                  <FormControl disabled={isSendingOrder}>
                    <RadioGroup
                      aria-labelledby="delivery-method-label"
                      name="delivery-method"
                      row
                      value={deliveryMethod}
                      onChange={(v) => setDeliveryMethod(
                          v.currentTarget.value as DeliveryMethod,
                      )}
                    >
                      <FormControlLabel
                        value="Pickup"
                        control={<Radio />}
                        label="Hentes"
                      />
                      <FormControlLabel
                        value="DeliveryRequested"
                        control={<Radio />}
                        label="Ønskes&nbsp;levert"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                {deliveryMethod === 'DeliveryRequested' && (
                  <InputField
                    fullWidth
                    error={!deliveryCommentValid}
                    sx={{ mt: 1 }}
                    disabled={isSendingOrder}
                    multiline
                    minRows={2}
                    label="Kommentar (maks 200 tegn)"
                    value={deliveryComment}
                    onChange={(e) => setOrderComment(e.slice(0, 200))}
                    required={deliveryMethod === 'DeliveryRequested'}
                  />
                )}
              </Box>
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
            flexGrow={1}
          >
            <Box
              display="flex"
              gap={1}
              flexGrow={1}
              sx={{
                alignItems: 'flex-end',
                '@media (max-width:800px)': {
                  flexDirection: 'column',
                  alignItems: 'normal',
                },
              }}
            >
              <Box display="flex" flex={1}>
                <RegionRadio
                  region={region}
                  setRegion={setRegion}
                  row
                  disabled={isSendingOrder || isExternal}
                />
              </Box>
              {type === 'Clothing' && (
                <Box gap={1} flex={1}>
                  <FormControlLabel
                    label="Hastebestilling"
                    disabled={items.length < 1 || isSendingOrder}
                    control={(
                      <Checkbox
                        sx={{ py: 0.5 }}
                        checked={urgent}
                        onChange={handleUrgent}
                      />
                    )}
                  />
                  <FormControlLabel
                    label="Ekstrabestilling"
                    disabled
                    control={<Checkbox sx={{ py: 0.5 }} checked={isExtra} />}
                  />
                </Box>
              )}
              <Box
                display="flex"
                py={0.5}
                mt={2}
                flex={1}
                gap={1}
                sx={{
                  justifyContent: 'flex-end',
                  '@media (max-width:800px)': {
                    justifyContent: 'space-between',
                  },
                }}
              >
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={items.length < 1 || isSendingOrder}
                  variant="contained"
                  color="error"
                  onClick={() => dispatch(emptyCart(type))}
                >
                  Tøm handlekurv
                </Button>
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={
                    items.length < 1
                    || !isValid
                    || isSendingOrder
                    || errorLoadingUser
                  }
                  variant="contained"
                  onClick={sendOrder}
                >
                  {isSendingOrder ? (
                    <CircularProgress size={24} />
                  ) : (
                    'Send bestilling'
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexGrow={1}
            flexDirection="column"
            alignItems="flex-end"
            sx={{
              alignItems: 'flex-end',
              '@media (max-width:800px)': {
                flexDirection: 'column',
                alignItems: 'normal',
                textAlign: 'start',
              },
            }}
          >
            <Alert severity="info">{message}</Alert>
          </Box>
        </Box>
      </Box>
      <UrgentModal
        open={urgentModal}
        onClose={() => setUrgentModal(false)}
        onConfirm={() => setUrgent(true)}
      />
    </Page>
  );
};
