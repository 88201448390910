import { Box, Button, Typography } from '@mui/material';
import { Modal } from '../../../components/modal';

export const UrgentModal = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal title="Hastebestilling" compactHeader open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>
          Hastebestilling brukes kun ved uforutsette hendelser og til erstatning av klær som ikke
          kan vente til månedsbestilling.
        </Typography>
        <Typography>
          Urgent orders are only used in the event of unforeseen events and to replace clothing that
          cannot wait until the monthly order.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>
            Avbryt
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
